import Vue from "vue"
import "../_main/utilities"
import ApiBase from "./_base"

Vue.prototype.$api.stages = new Vue({
  name: "api.stages",
  mixins: [ApiBase],
  data() {
    return {
      config: {
        recordKey: "stage",
        recordsKey: "stages",
        recordPath: Routes.stage_path,
        recordsPath: Routes.stages_path,
        submitPath: Routes.submit_stage_path,
        archivePath: Routes.archive_stage_path,
        restorePath: Routes.restore_stage_path,
      },
      params: {
        archiveStatus: 'active',
        searchTerm: '',
        autoCompleteTerm: '',
        board: '',
      }
    }
  },
})

export default Vue.prototype.$api.stages
