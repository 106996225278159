import Vue from 'vue'
import Clipboard from 'clipboard'
import sanitizeHtml from 'sanitize-html'
import 'helpers/notices'
const deepmerge = require('deepmerge')

export default window.utils = Vue.prototype.utils = {
  compact,
  copyText,
  deepmerge,
  dig,
  first,
  flatten,
  formatCurrency,
  formatDate,
  formatDateTime,
  formatDuration,
  formatDurationClock,
  formatNumber,
  formatTime,
  last,
  log,
  pick,
  pull,
  random,
  randomInt,
  removeElement,
  round,
  roundTime,
  toJSON,
  sanitize,
  shuffle,
  sortBy,
  uniq,
  uniqCompact,
  uniqMerge,
}

require('lodash')
Vue.prototype._ = _

function compact(array) {
  return [].concat(array).filter(Boolean)
}

function copyText(text, container) {
  return new Promise(function (resolve, reject) {
    var fakeElement = document.createElement('button')
    var clipboard = new Clipboard(fakeElement, {
      text: function () { return text },
      action: function () { return 'copy' },
      container: typeof container === 'object' ? container : document.body
    })
    clipboard.on('success', function (e) {
      Vue.prototype.$notices.send(`Copied ${text}`)
      clipboard.destroy()
      resolve(e)
    })
    clipboard.on('error', function (e) {
      clipboard.destroy()
      reject(e)
    })
    document.body.appendChild(fakeElement)
    fakeElement.click()
    document.body.removeChild(fakeElement)
  })
}

function dig(obj, path, defaultValue = undefined) {
  const travel = regexp =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj || result === null ? defaultValue : result;
}

function first(array) {
  return [].concat(array).shift()
}

function flatten(array) {
  return [].concat(...Array(array))
}

export function formatCurrency(value) {
  return formatNumber(value, { style: 'currency', currency: 'USD'})
}

function formatDuration(value) {
  return round(value / 3600.0, 2).toLocaleString("en",{minimumFractionDigits: 2})
}

function formatDurationClock(value) {
  if (!value) { return '00:00' }

  const hours = Math.floor(round(value) / 3600);
  const minutes = Math.floor((round(value) % 3600) / 60);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

function formatDate(value, format='MM/DD/YYYY') {
  if (value && moment(value).isValid()) {
    return moment(value).format(format)
  } else {
    value
  }
}

function formatDateTime(value, format='MM/DD/YYYY hh:mma') {
  return formatDate(value, format)
}

function formatNumber(value, options = {}) {
  if (!value) { return '' }

  let result = _.toNumber(value.replace(/[^0-9.]+/g, '')).toLocaleString('en-US', options)
  if (result == 'NaN') {
    return ''
  } else {
    return result
  }
}

function formatTime(value, format='hh:mma') {
  return formatDate(value, format)
}

function last(array) {
  return [].concat(array).pop()
}

// Provides a better way log multiple enties, and turns off loggin in production
function log(...stuff) {
  if (process.env.NODE_ENV != 'production')  { console.log(stuff) }
}

function pick(object, keys) {
  return keys.reduce((obj, key) => {
     if (object && object.hasOwnProperty(key)) {
        obj[key] = object[key];
     }
     return obj;
   }, {});
}

function pull(arr, ...removeList){
  var removeSet = new Set(removeList)
  return arr.filter(function(el){
    return !removeSet.has(el)
  })
}

function random(a = 1, b = 0) {
  const lower = Math.min(a, b);
  const upper = Math.max(a, b);
  return lower + Math.random() * (upper - lower);
};

function randomInt(a = 1, b = 0) {
  const lower = Math.ceil(Math.min(a, b));
  const upper = Math.floor(Math.max(a, b));
  return Math.floor(lower + Math.random() * (upper - lower + 1))
};

function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
      el.remove()
  } else if (typeof el.parentNode !== 'undefined' && el.parentNode !== null) {
      el.parentNode.removeChild(el)
  }
}

function round(value, decimals) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 0;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
}

function roundTime(interval, someMoment) {
  const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

// https://www.npmjs.com/package/sanitize-html
function sanitize(dirty) {
  return sanitizeHtml(dirty, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    transformTags: {
      'a': sanitizeHtml.simpleTransform('a', {target: '_blank'}),
    }
  })
}

function shuffle(list) {
  return list.concat().sort(() => utils.randomInt(-1,1))
};

function sortBy(list, key) {
  return list.concat().sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
};

// const sample = arr => {
//   const len = arr == null ? 0 : arr.length
//   return len ? arr[Math.floor(Math.random() * len)] : undefined
// }

// Simple method for converting an object to JSON
function toJSON(object) {
  return JSON.parse(JSON.stringify(object))
}

function uniq(array) {
  return [... new Set(array)]
}

function uniqCompact(array) {
  return [... new Set(compact(array))]
}

function uniqMerge(target, source) {
  return [...new Set([...target,...source])]
}

Vue.prototype.log = log
