import Vue from 'vue'
Vue.directive('select-on-focus', {
  bind(el, binding, vnode) {
    if (vnode.componentInstance) {
      vnode.componentInstance.$on('focus', event => event.target.select())
    } else {
      el.addEventListener('focus', event => event.target.select())
    }
  },
  unbind(el, binding, vnode) {
    if (vnode.componentInstance) {
      vnode.componentInstance.$off('focus')
    } else {
      el.removeEventListener('focus');
    }
  }
})
