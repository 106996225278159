import Vue from "vue"
import ApiBase from "./_base"

Vue.prototype.$api.attachments = new Vue({
  name: "api.attachments",
  mixins: [ApiBase],
  data() {
    return {
      params: {
        searchTerm: '',
        sortBy: 'name',
      },
      config: {
        recordsKey: "records",
        recordKey: "attachment",
        recordsPath: Routes.attachments_path,
        submitPath: Routes.submit_attachment_path,
      },
    }
  },
  computed: {
    sortByOptions() {
      return [
        // {value: 'priority', label: 'Priority'},
        {value: 'name', label: 'Name'},
        {value: 'recent', label: 'Recent'},
      ]
    }
  }
})

export default Vue.prototype.$api.attachments
