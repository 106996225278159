<template lang="pug">
  b-modal(v-model="active" v-bind="dialogProps")
    template(#default)
      component(
        v-bind="props"
        v-on="events"
        :is="component"
        @close="close"
      )
</template>

<script>
  import { ModalProgrammatic as Modal } from 'buefy'
  export default {
    name: 'BaseModal',
    props: {
      autoFocus: {
        type: Boolean,
        default: true,
      },
      component: [Object, Function, String],
      content: [String, Array],
      dialogProps: Object,
      props: Object,
      events: Object,
      returnRoute: Object,
    },
    data() {
      return {
        active: true
      }
    },
    watch: {
      active(isActive) {
        if (!isActive) {
          setTimeout(() => {
            this.$destroy()
            utils.removeElement(this.$el)
          }, 150)
        }
      }
    },
    methods: {
      close() {
        // this.$emit('close')
        this.active = false
      },
    },
    beforeMount() {
      document.body.appendChild(this.$el)
    },
    mounted() {
      app.$nextTick(() => { this.$el.focus() })
    },
    beforeDestroy() {
      app.log('BaseModal de')
      app.$router.push(this.returnRoute).catch(err => console.log(err))
    },
    open(options={}) {
      return Modal.open({
        component: this,
        hasModalCard: true,
        ...options
      })
    }
  }
</script>
