import Vue from 'vue'

Vue.component('MainContainer',      () => import("components/layout/MainContainer"))

Vue.component('ActiveTag',      () => import("components/ActiveTag"))
Vue.component('AppButton',      () => import("components/AppButton"))
Vue.component('AppColorPicker', () => import("components/AppColorPicker"))
Vue.component('DateSelect',     () => import("components/DateSelect"))
Vue.component('DateTimeSelect', () => import("components/DateTimeSelect"))
Vue.component('DurationSelect', () => import("components/DurationSelect"))
Vue.component('DurationDaysSelect', () => import("components/DurationDaysSelect"))
Vue.component('FileUploader',      () => import("components/attachments/FileUploader"))
Vue.component('RadioMenu',       () => import("components/RadioOptions"))
Vue.component('CheckboxOptions', () => import("components/CheckboxOptions"))
Vue.component('VueTable',       () => import("components/VueTable"))
Vue.component('FullTable',       () => import("components/VueTable"))
Vue.component('SimpleTable',    () => import("components/tables/SimpleTable"))
Vue.component('TaskList',       () => import("components/tasks/TaskList"))
Vue.component('Timer',       () => import("components/timeClock/Timer"))
Vue.component('VueEditor',      () => import("components/VueEditor"))
Vue.component('v-label',      () => import("components/Label"))

Vue.component('SearchInput',      () => import("components/elements/SearchInput"))
Vue.component('OwnerSelect',      () => import("components/cards/OwnerSelect"))
Vue.component('TeamSelect',      () => import("components/cards/TeamSelect"))

Vue.component('SideNav',    () => import("components/navigation/SideNav"))
Vue.component('Pagination', () => import("components/navigation/Pagination"))

Vue.component('Address',          () => import("components/fields/AddressField"))
Vue.component('AddressField',     () => import("components/fields/AddressField"))
Vue.component('AppointmentField', () => import("components/fields/AppointmentField"))
Vue.component('AreaField',        () => import("components/fields/AreaField"))
Vue.component('AttachmentsField', () => import("components/fields/AttachmentsField"))
Vue.component('BoatDetailsField', () => import("components/fields/BoatDetailsField"))
Vue.component('ContactField',     () => import("components/fields/ContactField"))
Vue.component('CheckboxField',     () => import("components/fields/CheckboxField"))
Vue.component('CurrencyField',     () => import("components/fields/CurrencyField"))
Vue.component('DateField',        () => import("components/fields/DateField"))
Vue.component('DatesField',       () => import("components/fields/DatesField"))
Vue.component('DropdownField',    () => import("components/fields/DropdownField"))
Vue.component('DurationField',    () => import("components/fields/DurationField"))
Vue.component('FabricField',      () => import("components/fields/FabricField"))
Vue.component('FrameDetailsField', () => import("components/fields/FrameDetailsField"))
Vue.component('ItemField',        () => import("components/fields/ItemField"))
Vue.component('ItemsField',       () => import("components/fields/ItemField"))
Vue.component('LinkField',        () => import("components/fields/LinkField"))
Vue.component('LocationField',    () => import("components/fields/LocationField"))
Vue.component('MarinaField',      () => import("components/fields/MarinaField"))
Vue.component('MaterialField',    () => import("components/fields/MaterialField"))
Vue.component('RidgedDoorField', () => import("components/fields/RidgedDoorField"))
Vue.component('TextField',        () => import("components/fields/TextField"))
