import Vue from "vue"
import ApiBase from "./_base"

export const defaultData = {
    id: null,
    user_id: null,
    team_id: null,
    card_id: null,
    startTime: null,
    endTime: null,
    duration: null,
    workDayNum: null,
    content: null,
}

if (!Vue.prototype.$api) { Vue.prototype.$api = {} }
Vue.prototype.$api.timeEntries = new Vue({
  name: "api.timeEntries",
  mixins: [ApiBase],
  data() {
    return {
      defaultData: defaultData,
      config: {
        recordKey: "timeEntry",
        recordsKey: "timeEntries",
        recordPath: Routes.time_entry_path,
        recordsPath: Routes.time_entries_path,
        submitPath: Routes.submit_time_entry_path,
      },
      params: {
        status: 'any',
        dateRange: [],
        teams: ['any'],
        members: ['any'],
        searchTerm: '',
      },
    }
  },
  // computed: {
  //   defaultData() { return defaultData }
  // }
})

export default Vue.prototype.$api.timeEntries
