export default {
  computed: {
    canCollapseColumns()  { return app.$account.features.collapseColumns },
    canExportData()       { return app.$account.features.export },
    canManageProduction() { return app.$account.features.productionManagement },
    canManageSales()      { return app.$account.features.salesManagement },
    canReviewCards()      { return app.$account.features.demo },
    canTrackTime()        { return app.$account.features.labor },
    canViewSchedule()     { return app.$account.features.schedule },
    canViewPlanning()     { return app.$account.features.planning },

    canViewCardEmails()   { return app.$account.features.emailCard && this.is.sales },
    canViewDaysInStage()  { return app.$account.features.daysInStage },
    canViewJobNumber()    { return app.$account.features.jobNumbers },
    canViewItems()        { return app.$account.features.items },
    canEditItems()        { return app.$account.features.items && this.is.admin },
    canViewNotes()        { return app.$account.features.notes },

    canViewProduction()   { return app.$user.is.production },
    canEditProduction()   { return app.$user.is.production && this.is.admin },


    canViewSales()        { return app.$user.is.sales },
    canEditSales()        { return app.$user.is.sales && this.is.admin },
    canEditTags()         { return app.$user.is.admin },

    canAddJob()           { return this.is.sales || this.is.admin },
    canAddWorkFlows()     { return this.is.admin && app.$account.features.workFlowTemplates && app.$state.editMode },
    canAddWorkOrders()    { return this.is.admin && app.$state.editMode },
    canAddOperations()    { return this.is.admin && app.$state.editMode },
    canAddFields()        { return this.is.admin && app.$state.editMode },

    canReviewTime()       { return this.canTrackTime && this.is.admin }

  },
  methods: {
    canViewStage(stage) { return stage.roles.every(stageRole => app.$user.roles.includes(stageRole))},
    canEditField(field) { return this.is.sales || this.is.admin },
    canViewField(field) { return true },
  }
}
