<template lang="pug">
  div.modal-card(
    tabindex="-1"
    @keyup.esc.stop="$emit('close')"
  )
    header.modal-card-head
      div.modal-card-title {{ title }}
    div.modal-card-body
      p(v-html="message")

    footer.modal-card-foot.level.has-content-spaced
      div.level-left
        app-button(v-if="action.match('archive')" icon="archive" @click="archive").is-archive Archive
        app-button(v-if="action.match('restore')" icon="restore" @click="restore").is-restore Restore
        app-button(v-if="action.match('destroy')" icon="delete" @click="destroy").is-danger Delete Forever!

      div.level-right
        app-button(v-if="action.match('remove')" icon="confirm" @click="remove").is-primary Remove
        app-button(v-if="action.match('confirm')" icon="confirm" @click="confirm").is-primary Make it happen!
        app-button(icon="cancel" @click="close").is-black
</template>

<script>
  import { ModalProgrammatic } from 'buefy'
  export default {
    name: 'ConfirmModal',
    props: {
      title: {
        type: String
      },
      message: {
        type: String
      },
      action: {
        type: String
      }
    },
    methods: {
      close() {
        this.$emit('close')
      },
      destroy() {
        this.$emit('destroy',this.action);
        this.$emit('confirmed',this.action);
        this.close();
      },
      archive() {
        this.$emit('archive',this.action);
        this.$emit('confirmed',this.action);
        this.close();
      },
      restore() {
        this.$emit('restore',this.action);
        this.$emit('confirmed',this.action);
        this.close();
      },
      confirm() {
        this.$emit('confirmed',this.action);
        this.close();
      },
      remove() {
        this.$emit('remove',this.action);
        this.$emit('confirmed',this.action);
        this.close();
      }
    },
    mounted() {
      app.$nextTick(() => { this.$el.focus() })
    },
    open(action="",title="",message="", options={}) {
      let modalOptions = {
        component: this,
        hasModalCard: true,
        props: {
          title: title,
          message: message,
          action: action
        }
      }
      return ModalProgrammatic.open(_.merge(modalOptions, options))
    }
  }
</script>
