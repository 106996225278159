import Vue from 'vue'

Vue.filter('upper_first',function(value){
  if (typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('formatDate', function (value, format) {
  if (moment(value).isValid()) {
    return moment(value).format(format)
  }
})


function formatNumber(value, options = {}) {
  let result = _.toNumber(value).toLocaleString('en-US', options)
  if (result == 'NaN') {
    return ''
  } else {
    return result
  }
}

Vue.filter("formatNumber", function (value) {
  return formatNumber(value).toLocaleString('en-US')
});


export function formatDuration(value) {
  return round(value / 3600.0, 2).toLocaleString('en', {minimumFractionDigits: 2})
}

export function formatDurationHours(value) {
  let output = moment.utc(Math.abs(value)*1000).format('HH:mm')
  // return output
  if(Math.sign(value) == -1) {
    return '-' + output
  } else {
    return output
  }
}

export function formatDurationDays(value) {
  return round(value / (3600.0 * 24))
}

Vue.filter("formatDuration", formatDuration);
Vue.filter("formatDurationHours", formatDurationHours);
Vue.filter("formatDurationDays", formatDurationDays);

Vue.filter("formatCurrency", function (value, options={}) {
  return formatNumber(value, { style: 'currency', currency: 'USD'})
});

Vue.filter("prettyJSON", function (value) {
  JSON.stringify(JSON.parse(value), null, 2);
});

Vue.filter('calendarDate', function (value) {
  if (moment(value).isValid()) {
    return moment(value).format('MM/DD/YYYY')
  }
})

Vue.filter('calendarTime', function (value) {
  if (moment(value).isValid()) {
    return moment(value).format('MM/DD/YYYY hh:mma')
  }
})

/**
 * Vue filter to round the decimal to the given place.
 * http://jsfiddle.net/bryan_k/3ova17y9/
 *
 * @param {String} value    The value string.
 * @param {Number} decimals The number of decimal places.
 */
export function round(value, decimals) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 0;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
}

Vue.filter('round', function (value, decimals) {
  return round(value, decimals);
});

// https://stackoverflow.com/questions/48377799/show-json-result-with-vue-js
// https://siongui.github.io/2017/03/15/vuejs-pretty-print-json-string/
Vue.filter('prettyJSON', function (value) {
  return JSON.stringify(value, null, 2);
})

Vue.filter('toUpper', function(value) {
  return value!=null?value.toUpperCase():value;
})
