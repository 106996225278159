import Vue from "vue"
import router from "_main/router"
import localData from "_main/localData"
import http from '_main/http'
import '_main/utilities'
import 'api/teams'
import policies from '_main/policies'
import FeedbackSubmissionModal from 'components/FeedbackSubmissionModal'

function defaultData() {
  return {
    authorization: null,
    authModes: {
      superAdmin: false,
      admin: true,
      owner: true,
      production: true,
      sales: true,
    },
    message: {
      type: null,
      content: null
    },
    feedback: {
      testimonial: null,
    },
    sleekJWT: null,
    response: null,
    users: [],
  }
}

Vue.prototype.$user = new Vue({
  name: "user",
  router: router,
  mixins: [localData, policies],
  data() {
    return defaultData()
  },
  computed: {
    authorized() { return !!this.jwt },
    jwt() {
      if(this.authorization) {
        let token = utils.dig(this, 'authorization', '').split('.')[1]
        return token ? JSON.parse(atob(token)) : null
      }
    },
    user() {
      return utils.dig(this, 'jwt.user')
    },
    ownerId() {
      return utils.dig(this, 'jwt.user.id')
    },
    ownerName() {
      return utils.dig(this, 'jwt.user.name')
    },
    profile() {
      return utils.dig(this, 'jwt.user')
    },
    roles(){
      return utils.dig(this, 'user.roles', {})
    },
    is() {
      return {
        admin: this.authModes.admin && utils.dig(this, 'roles.admin'),
        owner: this.authModes.owner && utils.dig(this, 'roles.owner'),
        production: this.authModes.production && utils.dig(this, 'roles.production'),
        sales: this.authModes.sales && utils.dig(this, 'roles.sales'),
        superAdmin: utils.dig(this, 'user.superAdmin'),
      }
    },
    isNot() {
      return _.mapValues(this.is, role => !role);
    },
    localData() {
      return _.pick(this, ['authorization', 'features', 'feedback'])
    },
  },
  watch: {
    authorization: {
      handler() {
        if (this.authorization) {
          app.$timeClock.fetchCurrentTimeClock()
          this.fetchUsers()
          app.$config.fetch().then(response => {
            this.sendIdentification()
          })
        }
      },
    },
  },
  methods: {
    resetData() {
      return _.assign(this, defaultData())
    },
    reset_password(email) {
      _.assign(this, defaultData())
      http.post(Routes.user_password_path(),{
        user: {
          email: email
        }
      }).then(response => {
        console.log(response);
      });
    },
    login(loginDATA) {
      _.assign(this, defaultData())
      http.post(Routes.user_session_path({ format: 'json' }), {
        user: utils.toJSON(loginDATA)
      }).then(response => {
        this.authorization = utils.dig(response.headers, 'authorization')
        this.$account.fetch()
        router.push({ name: 'root' })
      }).catch(error => {
        this.message.type = 'is-danger'
        this.message.content = utils.dig(error, 'response.data.error')
      })
    },
    logout() {
      http.delete(Routes.destroy_user_session_path()).then(() => {
        this.resetData()
        router.go({ name: 'login' })
      })
    },
    resetFeatures() {
      _.assign(this.features, defaultData().features)
    },
    fetchUsers() {
      if (this.authorization) {
        http.get(Routes.users_path()).then(response => { this.users = response.data.users })
      }
    },
    sendIdentification() {
      if (!!this.user) {
        heap.identify(this.user.email)
        heap.addUserProperties(this.user)
        this.setSleekUser()
      }
    },
    setSleekUser() {
      if(!!$sleek && !!this.sleekJWT) {
        this.$nextTick(() => { $sleek.setUser?.({token: this.sleekJWT }) })
      }
    },
    openFeedbackModal() {
      if(this.feedback.testimonial && moment().isBefore(moment(this.feedback.testimonial))) return

      FeedbackSubmissionModal.open('What\s one thing you like about Shopflow?', 'Got a minute?', {
        events: {
          submitted: (feedback) => {
            this.feedback.testimonial = moment().add(2, 'months')
          },
          close: () => {
            this.feedback.testimonial = moment().add(2, 'weeks')
          }
        }
      })
    }
  },
  created() {
    window.document.addEventListener('sleek:init', () => {
      this.setSleekUser()
    }, false);
  }
})

export default Vue.prototype.$user
