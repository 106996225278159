<template lang="pug">
  div.modal-card(
    tabindex="-1"
    @keyup.esc.stop="$emit('close')"
  )
    header.modal-card-head
      div.modal-card-subject {{ message }}
    div.modal-card-body
      b-field(:label="subject")
        b-input(
          v-model="submission.content"
          type="textarea"
          rows="3"
          v-autosize
        )

    footer.modal-card-foot.level.has-content-spaced
      div.level-left
        b-checkbox(v-model="submission.useForMarketing") Please use my feedback for marketing purposes!
      div.level-right
        app-button(icon="confirm" @click="submit" :disabled="!submission.content").is-primary Submit
        app-button(icon="cancel" @click="close").is-black No Thanks
</template>

<script>
  import { ModalProgrammatic } from 'buefy'
  export default {
    name: 'FeedbackSubmissionModal',
    props: {
      subject: {
        type: String
      },
      message: {
        type: String
      },
    },
    data() {
      return {
        submission: {
          subject: this.subject,
          content: null,
          useForMarketing: true,
        }
      }
    },
    methods: {
      close() {
        this.$emit('close')
      },
      submit() {
        return this.$http.post(Routes.submit_submission_path({submission: this.submission})).then(response => {
          this.$notices.send("Feedback Submitted, Thanks for Being Awesome!")
          this.$emit('submitted', response.data)
          this.close();
        })
      },
    },
    mounted() {
      app.$nextTick(() => { this.$el.focus() })
    },
    open(subject="", message="", options={}) {
      let modalOptions = {
        component: this,
        hasModalCard: true,
        canCancel: ['escape', 'outside'],
        props: {
          subject: subject,
          message: message,
        }
      }
      return ModalProgrammatic.open(_.merge(modalOptions, options))
    }
  }
</script>
