import Vue from 'vue'
import http from '_main/http'

Vue.prototype.$config = new Vue({
  name: 'config',
  data() {
    return {
      emailDomain: 'app.shopflow.io',
    }
  },
  methods: {
    fetch() {
      return this.$http.get(Routes.config_path()).then(response => {
        this.emailDomain = response.data.config.emailDomain
        app.$user.sleekJWT = response.data.config.sleekJWT
      })
    }
  },
  created() {
    this.fetch()
  },
})
