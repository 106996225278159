<template lang="pug">
  div.modal-card(
    tabindex="-1"
    @keyup.esc.stop="close"
  )
    header.modal-card-head
      p.modal-card-title
        template(v-if="$user.is.admin")
          b-field
            b-input(v-model="currentAttachment.fileName" expanded)
            app-button.is-save.is-small.ml-1(icon="save"  @click="updateName" size="small") Update File Name
        template(v-else)
          p.modal-card-title {{ currentAttachment.fileName }}
    section.modal-card-body
      a.image(:href="currentAttachment.url" target="_blank")
        img(:src="currentAttachment.previewUrl")
    footer.modal-card-foot.level.has-content-spaced(v-if="card")
      div.level-left
        template(v-if="currentAttachment._destroy")
          app-button(icon="restore" @click="unmarkForDestruction").is-restore Don't Delete
          app-button(icon="delete" @click="destroyAttachment").is-danger Confirm!
        template(v-else)
          app-button(icon="delete" @click="markForDestruction").is-danger Delete!

      div.level-right
        app-button(icon="skip-previous" @click="previous" :disabled="!hasPreviousAttachment")
        a.button.is-teal-500(:href="currentAttachment.url" target="_blank") View Orginal
        app-button(icon="skip-next" @click="next" :disabled="!hasNextAttachment")

        app-button(icon="cancel" @click="close").is-dark

    footer.modal-card-foot.level.has-content-spaced(v-else)
      div.spacer
      a.button.is-teal-500(:href="currentAttachment.url" target="_blank") View Orginal
      app-button(icon="cancel" @click="close").is-dark

</template>
<script>
  import 'api/attachments'
  import http from '_main/http'
  import { ModalProgrammatic } from 'buefy'

  export default {
    name: 'AttachmentsModal',
    props: {
      showAttachment: {
        type: Object
      },
      card: {
        type: Object
      },
    },
    data() {
      return {
        currentAttachment: {},
      }
    },
    computed: {
      attachments() {
        return utils.dig(this.card, 'attachments', [])
      },
      currentAttachmentIndex() {
        return _.findIndex(this.attachments, {id: this.currentAttachment.id})
      },
      lastAttachmentIndex() {
        return this.attachments.length - 1
      },
      nextAttachmentIndex() {
        return this.currentAttachmentIndex == this.lastAttachmentIndex ? null : this.currentAttachmentIndex + 1
      },
      hasNextAttachment() {
        return this.nextAttachmentIndex !== null
      },
      previousAttachmentIndex() {
        return this.currentAttachmentIndex == 0 ? null : this.currentAttachmentIndex - 1
      },
      hasPreviousAttachment() {
        return this.previousAttachmentIndex  !== null
      },
    },
    watch: {
      showAttachment: {
        handler() {
          this.currentAttachment = this.showAttachment
        },
        immediate: true
      }
    },
    methods: {
      // show(attachment, card) {
      //   this.card = card
      //   this.attachment = attachment
      //   this.active = true
      //   document.getElementById('AttachmentsModal').focus()
      //   this.canCancelCardDialog = false
      // },
      updateName(){
        app.$api.attachments.save({id: this.currentAttachment.id, name: this.currentAttachment.fileName}).then(record => {
          app.$notices.send('Attachement renamed')
          this.currentAttachment = record
        })
        app.log(this.currentAttachment)
      },
      close() {
        this.$emit('close')
      },
      next() {
        this.log('#attachmentsModal.next')
        if(this.hasNextAttachment) {
          this.currentAttachment = this.attachments[this.nextAttachmentIndex]
        }
      },
      previous() {
        this.log('#attachmentsModal.previous')
        if(this.hasPreviousAttachment) {
          this.currentAttachment = this.attachments[this.previousAttachmentIndex]
        }
      },
      markForDestruction() {
        this.$set(this.currentAttachment, "_destroy", true);
      },
      unmarkForDestruction() {
        this.$set(this.currentAttachment, "_destroy", false);
      },
      destroyAttachment() {
        http.delete(Routes.attachment_path(this.currentAttachment)).then(response => {
          let currentAttachmentIndex = this.currentAttachmentIndex
          this.card.attachments.splice(currentAttachmentIndex, 1)
          if(_.isEmpty(this.card.attachments)) {
            this.close()
          } else {
            let nextAttachmentIndex = _.min([currentAttachmentIndex, this.lastAttachmentIndex])
            this.currentAttachment = this.attachments[nextAttachmentIndex]
          }
        })
      }
    },
    mounted() {
      app.$nextTick(() => { this.$el.focus() })
    },
    open(attachment, card) {
      let modal = ModalProgrammatic.open({
        component: this,
        hasModalCard: true,
        props: {
          showAttachment: attachment,
          card: card,
        }
      })
      console.log(modal)
    }
  }
</script>
