import Vue from 'vue'
import Router from 'vue-router'
import user from '_main/user'
import state from '_main/state'

const router = new Router({
  mode: "history",
  // linkActiveClass: "is-active",
  routes: [
    {
      name: "root",
      path: "/",
      component: () => import("views/Jobs")
    },
    {
      name: "login",
      path: "/sf-login",
      component: () => import("views/login"),
      meta: { public: true },
    },
    {
      name: "attachments",
      path: "/attachments",
      component: () => import("views/Attachments")
    },
    {
      name: "calendar",
      path: "/calendar",
      component: () => import("views/Calendar")
    },
    {
      name: "contacts",
      path: "/contacts/:id?",
      props: true,
      component: () => import("views/Contacts")
    },
    {
      name: "gantt",
      path: "/gantt",
      props: true,
      component: () => import("views/Gantt")
    },
    {
      name: "qboAuth",
      path: "/qbo/auth",
      component: () => import("views/admin/Quickbooks")
    },
    {
      name: "qboDisconnect",
      path: "/qbo/disconnect",
      component: () => import("views/admin/Quickbooks")
    },
    {
      name: "reset_password",
      path: "/reset_password",
      component: () => import("views/ChangePassword"),
      meta: { public: true },
    },
    {
      name: "liveDemoSignup",
      path: "/live-demo-signup",
      component: () => import("views/LiveDemoSignup"),
      meta: { public: true },
    },

    {
      name: "printJob",
      path: "/jobs/:id/print",
      component: () => import("components/PrintJob"),
      meta: { printView: true },
      props: true
    },
    {
      name: "jobs",
      path: "/jobs/:id?",
      component: () => import("views/Jobs"),
      props: true
    },
    {
      name: "shopSchedule",
      path: "/schedule/weekly",
      component: () => import("views/Schedule")
    },
    {
      name: "stageStats",
      path: "/stage-stats",
      component: () => import("views/StageStats")
    },
    {
      name: "whatsNext",
      path: "/schedule/whats-next",
      component: () => import("views/WhatsNext")
    },
    {
      name: "teamDashboard",
      path: "/dashboards/team",
      component: () => import("views/TeamDashboard")
    },
    {
      name: "timeCalendar",
      path: "/time/calendar",
      component: () => import("views/TimeCalendar")
    },
    {
      name: "timeDetailed",
      path: "/time/detailed",
      component: () => import("views/TimeDetailed")
    },
    {
      name: "notes",
      path: "/notes",
      component: () => import("views/Notes")
    },
    {
      name: "emails",
      path: "/emails",
      component: () => import("views/Emails")
    },
    {
      name: "items",
      path: "/items",
      component: () => import("views/Items")
    },
    {
      name: "purchasing",
      path: "/purchasing",
      component: () => import("views/Purchasing")
    },
    {
      name: "taskBoard",
      path: "/task-board",
      component: () => import("views/WorkItems")
    },
    {
      name: "workItems",
      path: "/work-items",
      component: () => import("views/WorkItems")
    },
    {
      name: "admin",
      path: "/admin",
      component: () => import("components/Folder"),
      beforeEnter: ensureAdmin,
      children: [
        {
          name: "adminDashboard",
          path: "dashboard",
          component: () => import("views/admin/Dashboard")
        },
        {
          name: "areas",
          path: "areas",
          component: () => import("views/admin/Areas")
        },
        {
          name: "locations",
          path: "locations",
          component: () => import("views/admin/Locations")
        },
        {
          name: "adminBoards",
          path: "boards/:id?",
          props: true,
          component: () => import("views/admin/Boards")
        },
        {
          name: "laborReport",
          path: "laborReport",
          component: () => import("views/admin/LaborReport")
        },
        {
          name: "laborOperations",
          path: "laborOperations",
          component: () => import("views/admin/LaborOperations"),
        },
        // {
        //   name: "payrollReport",
        //   path: "payrollReport",
        //   component: () => import("views/admin/PayrollReport")
        // },
        {
          name: "quickbooks",
          path: "quickbooks",
          component: () => import("views/admin/Quickbooks")
        },
        {
          name: "tags",
          path: "tags",
          component: () => import("views/admin/Tags")
        },
        {
          name: "templates",
          path: "templates/:id?",
          props: true,
          component: () => import("views/admin/Templates")
        },
        {
          name: "teamMembers",
          path: "team-members/:id?",
          component: () => import("views/admin/TeamMembers"),
          props: true
        },
        {
          name: "teams",
          path: "teams",
          component: () => import("views/admin/Teams")
        },
        {
          name: "adminTimeSummary",
          path: "time/summary",
          component: () => import("views/admin/TimeSummary")
        },
        {
          name: "adminTimeDetailed",
          path: "time/detailed",
          component: () => import("views/TimeDetailed")
        },
        {
          name: "adminMemberTimeCalendar",
          path: "time/calender/member/:userId",
          component: () => import("views/TimeCalendar"),
          props: true,
        },
      ]
    },
    {
      name: "superadmin",
      path: "/superadmin",
      // beforeEnter: ensureSuperAdmin,
      component: () => import("components/Folder"),
      children: [
        {
          name: "adminAccounts",
          path: "accounts",
          component: () => import("views/superadmin/Accounts")
        },
        {
          name: "adminUsers",
          path: "users",
          component: () => import("views/superadmin/Users")
        },
        // {
          // name: "accountsReport",
          // path: "reports/accounts",
          // component: () => import("views/superadmin/reports/Accounts")
        // },
      ]
    },
  ]
})

function ensureAdmin(to, from, next) {
  if (user.is.admin) {
    next()
  } else {
    next({ name: 'root' })
  }
}

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  if(user.authorized || routeTo.meta.public) {
    next()
  } else {
    redirectToLogin()
  }

  function redirectToLogin() {
    routeTo.name == "login" ? next() : next({ name: 'login' })
  }
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
  // Complete the animation of the route progress bar.
  // NProgress.done()
})

export default router
