import Vue from "vue"
import ApiBase from "./_base"

Vue.prototype.$api.boards = new Vue({
  name: "api.boards",
  mixins: [ApiBase],
  data() {
    return {
      config: {
        recordKey: "board",
        recordsKey: "boards",
        recordPath: Routes.board_path,
        recordsPath: Routes.boards_path,
        submitPath: Routes.submit_board_path,
        archivePath: Routes.archive_board_path,
        restorePath: Routes.restore_board_path,
      },
      params: {
        archiveStatus: 'active',
        searchTerm: '',
      }
    }
  },
  computed: {
    stages() {
      return utils.flatten(this.records.map(board => board.stages))
    },
  }
})

export default Vue.prototype.$api.boards
