<template lang="pug">
  div.modal-card(
    tabindex="-1"
    @keyup.esc.stop="$emit('close')"
  )
    header.modal-card-head
      div.modal-card-title Board
    div.modal-card-body
      b-field(label="Name")
        b-input(v-model="board.name")

      b-field(label="Description")
        b-input(v-model="board.content")

      b-field(label="Order")
        b-input(v-model="board.position")

    footer.modal-card-foot.level.has-content-spaced
      div.level-left
        app-button(icon="archive"  v-if="board.id && board.archivedAt==null"  @click="showConfirmModal('archive')").is-archive Archive
        app-button(icon="restore" v-if="board.id && board.archivedAt!=null"  @click="showConfirmModal('restore')").is-restore Restore

      div.level-right
        app-button(icon="save" @click="save").is-save Save
        app-button(icon="saveClose" @click="saveAndClose").is-saveClose Save &amp; Close
        app-button(icon="cancel" @click="close").is-dark
</template>

<script>
  import { ModalProgrammatic } from 'buefy'
  import boardsAPI from 'api/boards'
  import ConfirmModal from 'components/ConfirmModal'
  export default {
    props: {
      board: {
        type: Object
      },
    },
    data () {
      return {
        boardsAPI
      }
    },
    methods: {
      archive() {
        this.boardsAPI.archive(this.board.id).then(() => {
          this.$notices.send("Board Archived.");
          this.close();
        });
      },
      restore() {
        this.boardsAPI.restore(this.board.id).then(() => {
          this.$notices.send("Board Restored.");
          this.close();
        });
      },
      save() {
        return this.boardsAPI.save(this.board).then(response => {
          this.$notices.send("Board Updated.")
          this.$emit('saved', response.data)
        })
      },
      saveAndClose() {
        this.save().then(() => {
          this.close()
        })
      },
      close() {
        this.$emit('close')
      },
      showConfirmModal(action) {
        var title = "";
        var message = "";
        if (action=='archive') {
          title = "Archiving Board";
          message = "Do you want to archive the " + this.board.name;
        }
        if (action=='restore') {
          title = "Restoring Board";
          message = "Do you want to restore the " + this.board.name;
        }
        ConfirmModal.open(action,title,message,{
          events: {
            confirmed: action => {
              if (action=='archive') {
                this.archive();
              }
              if (action=='restore') {
                this.restore();
              }
            }
          }
        })
      }
    },
    mounted() {
      app.$nextTick(() => { this.$el.focus() })
    },
    open(board={}, options={}) {
      let modalOptions = {
        component: this,
        hasModalCard: true,
        props: {
          board: board,
        }
      }
      return ModalProgrammatic.open(_.merge(modalOptions, options))
    }
  }
</script>
