import Vue from 'vue'
import http from '_main/http'
import router from "_main/router"
import localData from "_main/localData"

function defaultData() {
  return {
    id: null,
    refId: null,
    name: '',
    tenant_name: '',
    boards: [],
    features: {},
  }
}

Vue.prototype.$account = new Vue({
  name: 'account',
  mixins: [localData],
  data() {
    return defaultData()
  },
  computed: {
    localData() {
      return _.pick(this, ['id', 'name'])
    }
  },
  asyncComputed: {
    users() {
      if (this.$user.authorization) {
        return http.get(Routes.active_users_path()).then(response => { return response.data.users })
      }
    },
  },
  methods: {
    switch(accountId) {
      this.$http.post(Routes.switch_account_path(accountId)).then(response => {
        this.fetch()
        router.go()
      })
    },
    getBoard(key) {
      return this.boards.find((board) => {
        return board.name === key || board.id === key
      })
    },
    fetch() {
      return http.get(Routes.current_account_path()).then(response => {
        this.$user.resetFeatures()
        _.assign(this, defaultData(), response.data.account)
        _.assign(this.$account.features, this.features)
        _.assign(this.$user.data, this.data)
        _.assign(this.$user.profile, this.profile)
      })
    }
  }
})
