<template lang="pug">
  div.modal-card(
    tabindex="-1"
    @keyup.esc.stop="$emit('close')"
  )
    header.modal-card-head
      div.modal-card-title Stage
    div.modal-card-body

      b-field(label="Name")
        b-input(v-model="stage.name")

      b-field(label="Stage Type")
        b-select(v-model="stage.stageType")
          option(v-for="type in stageTypes" :key="type.key" :value="type.key") {{ type.name }}

      b-field(label="Stage Color")
        AppColorPicker(v-model="stage.stageColor")

      b-field(label="Lead Time Days")
        DurationDaysSelect.is-5rem-wide(v-model="stage.leadTimeDuration")

      b-field(label="Description")
        b-input(v-model="stage.content")

      b-field(label="Board")
        b-select.is-small(
          v-model="stage.board_id"
          expanded="expanded"
        )
          option(v-for="board in $api.boards.all" :key="board.id" :value="board.id") {{ board.name }}

      b-field(label="Order")
        b-input(v-model="stage.position")

    footer.modal-card-foot.level.has-content-spaced
      div.level-left
        app-button(icon="archive" v-if="stage.id && stage.archivedAt==null"  @click="showConfirmModal('archive')").is-archive Archive
        app-button(icon="restore" v-if="stage.id && stage.archivedAt!=null"  @click="showConfirmModal('restore')").is-restore Restore

      div.level-right
        app-button(icon="save"      @click="save").is-save Save
        app-button(type="saveClose" @click="saveAndClose").is-saveClose Save &amp; Close
        app-button(type="cancel"    @click="close").is-dark
</template>

<script>
  import { ModalProgrammatic } from 'buefy'
  import stagesAPI from 'api/stages'
  import ConfirmModal from 'components/ConfirmModal'
  export default {
    props: {
      stage: {
        type: Object
      },
    },
    data () {
      return {
        stagesAPI,
        stageTypes: [
          {key: 'intake',   name: 'Intake' },
          {key: 'active',   name: 'Active'},
          {key: 'backlog',  name: 'Backlog'},
          {key: 'wip',      name: 'WIP'},
          {key: 'hold',     name: 'Hold'},
          {key: 'archive',  name: 'Archive'},
          {key: 'complete', name: 'Complete'}
        ]
      }
    },
    methods: {
      archive() {
        this.stagesAPI.archive(this.stage.id).then(() => {
          this.$notices.send("Stage Archived.");
          this.close();
        });
      },
      restore() {
        this.stagesAPI.restore(this.stage.id).then(() => {
          this.$notices.send("Stage Restored.");
          this.close();
        });
      },
      save() {
        return this.stagesAPI.save(this.stage).then(response => {
          this.$notices.send("Stage Updated.")
          this.$emit('saved', response.data)
        })
      },
      saveAndClose() {
        this.save().then(() => {
          this.close()
        })
      },
      close() {
        this.$emit('close')
      },
      showConfirmModal(action) {
        var title = "";
        var message = "";
        if (action=='archive') {
          title = "Archiving Stage";
          message = "Do you want to archive the " + this.stage.name;
        }
        if (action=='restore') {
          title = "Restoring Stage";
          message = "Do you want to restore the " + this.stage.name;
        }
        ConfirmModal.open(action,title,message,{
          events: {
            confirmed: action => {
              if (action=='archive') {
                this.archive();
              }
              if (action=='restore') {
                this.restore();
              }
            }
          }
        })
      }
    },
    mounted() {
      app.$nextTick(() => { this.$el.focus() })
    },
    open(stage={}, options={}) {
      let modalOptions = {
        component: this,
        hasModalCard: true,
        props: {
          stage: stage,
        }
      }
      return ModalProgrammatic.open(_.merge(modalOptions, options))
    }
  }
</script>
