<template lang="pug">
  div#app
    SuperAdminBar
    top-bar
    router-view
</template>

<script>

export default {
  name: 'App',
  components: {
    TopBar: () => import('components/navigation/TopBar'),
    SuperAdminBar: () => import('components/navigation/SuperAdminBar'),
  },
  watch: {
    windowHeight() {
      let vh = this.windowHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
  mounted() {
    document.documentElement.classList.remove('has-spinner-active')
    this.$nextTick(() => { app.$account.fetch() } )
  },
}
</script>
