import Vue from 'vue'
import Appsignal from "@appsignal/javascript"
import { errorHandler } from "@appsignal/vue"

if ( process.env.NODE_ENV != 'development') {
  const pushKey = process.env.NODE_ENV == "production" ? "19e0fc85-5b0d-4731-9b45-b70b29983997" : "f156073c-2e84-4050-a148-cc14b56e4d6c"

  Vue.prototype.appsignal = new Appsignal({
    key: pushKey,
    revision: process.env.APP_REVISION
  })

  Vue.config.errorHandler = errorHandler(Vue.prototype.appsignal, Vue)
}
