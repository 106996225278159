<template lang="pug">
  div.modal-card(
    tabindex="-1"
    @keyup.esc.stop="$emit('close')"
  )
    header.modal-card-head
      div.modal-card-title Comment

    section.modal-card-body
      b-field(label="Timestamp")
        DateTimeSelect(v-model="comment.commentedAt" position="is-bottom-right")
      b-field(label="Author")
        b-select(v-model="comment.user" expanded placeholder="Select User")
          option(v-for="user in $user.users" :key="user.id" :value="user") {{ user.name }}
      b-field(label="Comment")
        VueEditor(v-model="comment.content")
    footer.modal-card-foot
      app-button(type="cancel" @click="cancel") Cancel
      app-button(type="save" @click="save") Submit
</template>

<script>
  import { ModalProgrammatic } from 'buefy'
  export default {
    props: {
      comment: {
        type: Object
      },
    },
    methods: {
      save() {
        this.$emit('saved', this.comment)
        this.$emit('close')
      },
      cancel() {
        this.$emit('cancel', this.comment)
        this.$emit('close')
      },
    },
    mounted() {
      app.$nextTick(() => { this.$el.focus() })
    },
    open(comment, options={}) {
      let modalOptions = {
        component: this,
        hasModalCard: true,
        props: {
          comment: comment,
        }
      }
      return ModalProgrammatic.open(_.merge(modalOptions, options))
    }
  }
</script>
