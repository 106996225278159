import Vue from 'vue'
import VueCookies from 'vue-cookies'
import utils from '_main/utilities.js'
require('lodash')

VueCookies.config('7d')
Vue.use(VueCookies)

export default {
  computed: {
    localData() {
      return this.$data
    }
  },
  watch: {
    localData: {
      handler: 'setLocalData',
      deep: true
    }
  },
  methods: {
    storageKey() { return utils.compact([this.$options.name || this.$route.name || this.$route.path, localStorage.getItem('version')]).join('.') },
    setLocalData() {
      localStorage.setItem(this.storageKey(), JSON.stringify(this.localData))
      // this.log('setLocalData', this.storageKey(), this.localData)
    },
    getLocalData() {
      let localDataString = localStorage.getItem(this.storageKey())
      let localDataJSON   = JSON.parse(localDataString) || {}
      this.fixDateRanges(localDataJSON)
      // this.log('getLocalData', this.storageKey(), this.localData, localDataJSON)
      Object.assign(this, utils.deepmerge(this.localData, localDataJSON, { arrayMerge: utils.uniqMerge }))
    },
    fixDateRanges(localDataJSON) {
      let customDueDateRange = this.utils.dig(localDataJSON, 'params.customDueDateRange')
      let customCompletedDateRange = this.utils.dig(localDataJSON, 'params.customCompletedDateRange')

      if (customDueDateRange && customDueDateRange.length == 2) {
        localDataJSON.params.customDueDateRange = customDueDateRange.map (date => new Date(date))
      }

      if (customCompletedDateRange && customCompletedDateRange.length == 2) {
        localDataJSON.params.customCompletedDateRange = customCompletedDateRange.map (date => new Date(date))
      }
    }
  },
  created() {
    this.getLocalData()
  }
}
