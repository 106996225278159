import Vue from 'vue'

import { extendMoment } from 'moment-range'
import AsyncComputed from "vue-async-computed"
import Buefy from 'buefy'
import Chart from 'chart.js'
import Chartkick from 'vue-chartkick'
import moment from 'moment'
import Router from "vue-router"
import Vuelidate from 'vuelidate'
import VueMeta from "vue-meta"
import VueWindowSize from 'vue-window-size'

import App from 'app'
import router from '_main/router'

import '@mdi/font/scss/materialdesignicons'

Vue.use(AsyncComputed)
Vue.use(Buefy)
Vue.use(Chartkick.use(Chart))
Vue.use(require('vue-moment'))
Vue.use(Router)
Vue.use(Vuelidate)
Vue.use(VueMeta)
Vue.use(VueWindowSize)

require('_main/account')
require('_main/components')
require('_main/config')
require('_main/errorCatcher')
require('_main/filters')
require('_main/http')
require('_main/modals')
require('_main/navigate')
require('_main/state')
require('_main/timeClock')
require('_main/user')
require('_main/utilities')
require('api/jobs')
require('directives/autosize')
require('directives/select-on-focus')
require('helpers')

require('styles/application.scss')

if (document.getElementById("app")) {
  Vue.config.ignoredElements = ['trix-toolbar', 'trix-editor']
  window.moment = extendMoment(moment)
  window.app = new Vue({
    name: 'Shopflow',
    el: '#app',
    router,
    render: h => h(App)
  })
}
