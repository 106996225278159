import Vue from 'vue'
import CalendarTimeEntryModal from 'components/CalendarTimeEntryModal'
import http from '_main/http'

Vue.prototype.$timeClock = new Vue({
  name: "TimeClock",
  data() {
    return {
      currentTimeCard: {},
      currentTimeEntry: {},
      userId: null,
      timeRangeStart: null,
      timeRangeEnd: null,
    }
  },
  asyncComputed: {
    timeEntries: {
      lazy: true,
      default: [],
      get() {
        return this.fetchTimeEntries({users: this.userId, timeRangeStart: this.timeRangeStart, timeRangeEnd: this.timeRangeEnd})
      }
    },
  },
  computed: {
    clockedIn() {
      return !!this.currentTimeEntry.id && !this.currentTimeEntry.endTime
    }
  },
  methods: {
    fetchCurrentTimeClock() {
      this.fetchCurrentTimeEntry()
      this.fetchCurrentTimeCard()
    },
    fetchTimeEntries(params) {
      return app.$http.get(Routes.time_entries_path(params)).then(response => {
        return response.data.timeEntries
      })
    },
    fetchCurrentTimeEntry() {
      http.get(Routes.current_time_clock_path()).then(response => { this.setCurrentTimeEntry(response.data.currentTimeEntry) })
    },
    setCurrentTimeEntry(timeEntry) {
      this.currentTimeEntry = timeEntry
    },
    fetchCurrentTimeCard() {
      http.get(Routes.current_time_card_path()).then(response => { this.setCurrentTimeCard(response.data.timeCard) })
    },
    setCurrentTimeCard(timeCard) {
      this.currentTimeCard = timeCard
    },
    start(timeEntry={}) {
      app.$http.get(Routes.new_time_entry_path({timeEntry: timeEntry})).then(response => {
        this.openCalendarTimeEntryModal(response.data.timeEntry)
      })
    },
    stop() {
      this.openCalendarTimeEntryModal({...this.currentTimeEntry, endTime: app.$state.now})
    },
    edit(timeEntry) {
      if (this.currentTimeEntry.card_id === timeEntry.card_id) {
        this.openCalendarTimeEntryModal(this.currentTimeEntry)
      } else if (timeEntry.id) {
        app.$http.get(Routes.time_entry_path(timeEntry.id)).then(response => {
          this.openCalendarTimeEntryModal(response.data.timeEntry)
        })
      } else {
        app.$http.get(Routes.new_time_entry_path({timeEntry: timeEntry})).then(response => {
          this.openCalendarTimeEntryModal(response.data.timeEntry)
        })
      }

    },
    openCalendarTimeEntryModal(timeEntry = {}) {
      app.log('openCalendarTimeEntryModal', timeEntry)
      CalendarTimeEntryModal.open(timeEntry, {
        userId: timeEntry.userId,
        events: {
          saved: this.fetchCurrentTimeClock,
          mounted: () => {
            this.$state.displayTimeClockCalendar = false
          },
          close: () => {
            this.$state.displayTimeClockCalendar = true
            this.$asyncComputed.timeEntries.update()
          },
        },
        onCancel: () => {
          this.$state.displayTimeClockCalendar = true
          this.$asyncComputed.timeEntries.update()
        },
      })
    }
  }
})

export default Vue.prototype.$timeClock
