import Vue from 'vue'
import http from '_main/http'
import ApiBase from './_base'
import localData from '_main/localData'
import { unparse } from 'papaparse';

Vue.prototype.$api.jobs = new Vue({
  name: 'api.jobs',
  mixins: [ApiBase, localData],
  data() {
    return {
      jobsView: 'JobsBoard',
      board: 'any',
      timestamp: new Date(),
      updatedAt: new Date(),
      params: {
        searchTerm: '',
        groupBy: 'stage',
        sortBy: 'name',
        dueDateRange: 'any',
        customDueDateRange: [],
        lastUpdated: 'any',
        // completedStatus: 'recent',
        areas: ['any'],
        marinas: ['any'],
        stages: ['any'],
        teams: ['any'],
        owners: ['any'],
        tags: ['any'],
        types: ['any'],
      },
      config: {
        recordKey: 'card',
        recordsKey: 'cards',
        recordPath: Routes.job_path,
        recordsPath: Routes.jobs_path,
        submitPath: Routes.submit_job_path,
        archivePath: Routes.archive_job_path,
        restorePath: Routes.restore_job_path,
      },

    }
  },
  asyncComputed: {
    query: {
      default: {},
      get() {
        return this.fetchQuery(this.whereParams)
      }
    },
  },
  computed: {
    whereParams() {
      return {
        ...this.params,
        board: this.board,
        page: this.page,
        perPage: this.perPage,
        sortInfo: this.sortInfo,
        timestamp: this.timestamp,
      }
    },
    groups() {
      return utils.dig(this.query, 'groups', [])
    },
    stages() {
      return utils.dig(this.query, 'stages', [])
    },
    teams() {
      return utils.dig(this.query, 'teams', [])
    },
    owners() {
      return utils.dig(this.query, 'owners', [])
    },
    tags() {
      return utils.dig(this.query, 'tags', [])
    },
    types() {
      return utils.dig(this.query, 'types', [])
    },
    areas() {
      return utils.dig(this.query, 'areas', [])
    },
    marinas() {
      return utils.dig(this.query, 'marinas', [])
    },
    localData() {
      return _.pick(this, ['board', 'jobsView', 'params'])
    },
    newCardStage() {
      return utils.first(this.stages)
    },
    viewOptions() {
      return [
        {
          label: 'Columns',
          value: 'JobsBoard',
          icon: 'view-column'
        },
        {
          label: 'Table',
          value: 'JobsTable',
          icon: 'view-list'
        }
      ]
    },
    groupByOptions() {
      return [
        {label: 'Stage', value: 'stage'},
        {label: 'Due Date', value: 'week'},
        {label: 'Team', value: 'team'},
      ]
    },
    sortByOptions() {
      return [
        // {value: 'priority', label: 'Priority'},
        {value: 'name', label: 'Name'},
        {value: 'dueDate', label: 'Due Date'},
        {value: 'rating', label: 'Rating'},
        {value: 'stageDays', label: 'Days in Stage'},
        {value: 'lastUpdated', label: 'Last Updated'},
      ]
    }
  },
  methods: {
    clearFilters() {
      this.board = 'any'
      this.params = {
        searchTerm: '',
        groupBy: 'stage',
        sortBy: 'name',
        dueDateRange: 'any',
        customDueDateRange: [],
        lastUpdated: 'any',
        // completedStatus: 'recent',
        areas: ['any'],
        marinas: ['any'],
        stages: ['any'],
        teams: ['any'],
        owners: ['any'],
        tags: ['any'],
      }
    },
    touch() {
      this.updatedAt = new Date()
    },
    refreshIfNeeded() {
      this.timestamp = this.updatedAt
    },
    refresh() {
      this.touch()
      this.refreshIfNeeded()
    },
    fetchQuery(params = {}) {
      return http.get(Routes.job_query_path(params)).then((response) => {
        return response.data
      })
    },
    where(params = {}) {
      this.loadingStarted()
      return http.get(this.config.recordsPath(params)).then((response) => {
        this.paginationInfo = response.data.pagination
        // this.groups = response.data.groups
        this.loadingFinished()
        return response.data[this.config.recordsKey]
      })
    },
    csvRecords(records) {
      return unparse(records.map(record => {
        return {
          ...record,
          workOrders: record.workOrders.map(workOrder => workOrder.name).join(', '),
          fields: JSON.stringify( record.fields.map(field => { return {[field.name]: field.data } })),
          area: utils.dig(record, 'area.data.name'),
          stage: utils.dig(record, 'stage.name'),
          completedBy: utils.dig(this.$api.members.fetch(utils.dig(record, 'completedBy_id')), 'name'),
        }
      }))
    },
    sortCards(cards) {
      let sortedCards = []
      sortedCards = _.sortBy(cards, this.params.sortBy)
      return sortedCards
    },
    assignTemplate(card, templateId) {
      app.log('assignTemplate(card, templateId)', card, templateId)
      if (!card.name || !templateId) { return Promise.reject('Card and Template IDs are required')}
      return this.$http.post(Routes.assignCardTemplate_path(), {card: utils.toJSON(card), templateId }).then((response) => {
        return response.data.card
      })
    },
    applyWorkflow(card, templateId) {
      if (!card.id || !templateId) { return Promise.reject('Card and Template IDs are required')}
      return this.$http.post(Routes.applyCardWorkflow_path(), {card: utils.toJSON(card), templateId }).then((response) => {
        return response.data.card
      })
    },
    addWorkOrder(card, templateId) {
      if (!card.id || !templateId) { return Promise.reject('Card and Template IDs are required')}
      return this.$http.post(Routes.addCardWorkOrder_path(), {card: utils.toJSON(card), templateId }).then((response) => {
        return response.data.card
      })
    },
  },
})
export default Vue.prototype.$api.jobs
