<template lang="pug">
  div.modal-card(
    tabindex="-1"
    @keyup.esc.stop="cancel"
  )
    header.modal-card-head
      div.modal-card-title {{ title }}
    div.modal-card-body
      b-field
        b-checkbox(v-model="contact.isCustomer") Quickbooks Customer?
      b-field(label="Company or Individual" expanded)
        b-select(v-model="contact.type" expanded)
          option(value="Company") Company
          option(value="Person") Individual
      b-field(label="Name")
        b-input(v-model="contact.name" :name="contact.type === 'Company' ? 'company_name' : 'name'")

      b-field(label="Email").T-emailAddress
        b-input(v-model="contact.emailAddress" name="email_address")
        template(slot="label")
          span.mr-1 Email:
          small: em: a.has-text-info(:href="`mailto:${contact.emailAddress}`") {{ contact.emailAddress }}

      b-field(label="Phone")
        b-input(v-model="contact.phoneNumber" name="phone_number")

      b-field(label="Address")
        b-input(v-model="contact.delivery" name="address")
      b-field(grouped).is-block-touch
        b-field(label="City" expanded)
          b-input(v-model="contact.city" name="city" expanded)
        b-field(label="State")
          b-input(v-model="contact.state" name="state")
        b-field(label="Zip")
          b-input(v-model="contact.zip" name="zip")

      b-field(label="Cards")
        div.panel.mt-1.mb-1
          a.panel-block.is-block.is-size-7(v-for="card in contact.cards" :key="card.id" @click.stop="$navigate.viewCard(card)")
            div
              b-icon(icon="clipboard-check-outline" size="is-small")
              span.name {{ card.name }}

    footer.modal-card-foot
      app-button(type="cancel" @click="cancel()") Cancel
      app-button(type="save" @click="save()") Save

</template>

<script>
  import { ModalProgrammatic } from 'buefy'
  export default {
    props: {
      contact: {
        type: Object
      },
    },
    computed: {
      title() {
        return this.contact.id ? 'Edit Contact' : 'New Contact'
      }
    },
    methods: {
      save() {
        this.$http.post(Routes.submit_contact_path(), {
          contact: utils.toJSON(this.contact)
        }).then(response => {
          this.$emit('saved', response.data.contact)
          this.$state.$emit('contactModal.saved', response.data.contact)
          this.$emit('close')
        })
      },
      cancel() {
        this.$emit('close')
      }
    },
    mounted() {
      app.$nextTick(() => { this.$el.focus() })
    },
    open(contact, options={}) {
      let modalOptions = {
        canCancel: ['escape'],
        component: this,
        hasModalCard: true,
        props: {
          contact: contact,
        }
      }
      return ModalProgrammatic.open(_.merge(modalOptions, options))
    }
  }
</script>
