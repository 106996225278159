import Vue from 'vue'
import { ModalProgrammatic as Modal } from 'buefy'
import BaseModal from 'components/modals/BaseModal'

if (!Vue.prototype.$modals) Vue.prototype.$modals = {}

function openModal (options={}) {
  Modal.open({
    component: BaseModal,
    hasModalCard: true,
    ...options
  })
}

Vue.prototype.$openAreaModal = (area, options={}) => {
  openModal({
    component: () => import("components/areas/AreaModal"),
    props: {area},
    ...options
  })
}

Vue.prototype.$openAttachmentsModal  = (timeEntry, options={}) => {
  openModal({
    component: () => import("components/timeEntries/timeEntryModal"),
    props: {timeEntry},
    ...options
  })
}

Vue.prototype.$modals.attachments = require('components/attachments/AttachmentsModal').default
Vue.prototype.$modals.board       = require('components/boards/BoardModal').default
Vue.prototype.$modals.comment     = require('components/comments/CommentModal').default
Vue.prototype.$modals.contact     = require('components/contacts/ContactModal').default
Vue.prototype.$modals.stage       = require('components/stages/StageModal').default
