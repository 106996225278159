import Vue from 'vue'
import localData from "_main/localData"

Vue.prototype.$state = new Vue({
  name: "state",
  mixins: [localData],
  data() {
    return {
      loading: false,
      cardLoading: false,
      userIsTouching: false,
      displayImageDialog: false,
      navbarIsActive: false,
      sideNavDisplayed: true,
      superAdminBarDisplayed: false,
      displayTimeClockCalendar: true,
      editMode: false,
      now: new Date(),
    }
  },
  computed: {
    menuToggleIcon () {
      return app.$state.sideNavDisplayed ? 'backburger' : 'forwardburger'
    },
    localData() {
      return _.pick(this, ['userIsTouching'])
    },
    printView() {
      return app.$route.meta.printView
    },
    readOnly() {
      return !this.editMode
    }
  },
  methods: {
    toogleSideNavDisplay() {
      app.log('toogleSideNavDisplay', this.sideNavDisplayed)
      this.sideNavDisplayed = !this.sideNavDisplayed
    },
    toogleSuperAdminBarDisplay() {
      if (app.$user.is.superAdmin) {
        app.log('toogleSuperAdminBarDisplay', this.superAdminBarDisplayed)
        this.superAdminBarDisplayed = !this.superAdminBarDisplayed
      }
    }
  },
  created() {
    setInterval(() => this.now = new Date(), 1000 * 1)
  }
})

export default Vue.prototype.$state
