<template lang="pug">
  b-field.operationSelect(expanded)
    b-autocomplete(
      :value="operationTitle(selectedOperation)"
      :data="$api.laborOperations.records"
      @typing="$api.laborOperations.search"
      @select="updateOperation"
      open-on-focus
      dropdown-position="bottom"
      v-bind="$attrs"
      v-select-on-focus
      keep-first
    )
      template(v-slot="{ option: operation}")
        div.box.p-2.m-0.is-flex
          div.is-flex {{ operationTitle(operation) }}
          div.spacer
          b-taglist.is-flex
            b-tag(v-if="operation.teamName" type="is-blue-900") {{ operation.teamName }}
            b-tag(v-if="operation.ownerName" type="is-secondary") {{ operation.ownerName }}
            b-tag(v-if="operation.dueDate" type="is-light") {{ utils.formatDate(operation.dueDate) }}

</template>

<script>
  import 'api/laborOperations'

  export default {
    name: 'OperationSelect',
    props: ['value', 'size', 'placeholder'],
    data() {
      return {
        selectedOperation: {}
      }
    },
    watch: {
      value: {
        handler() {
          if (this.value) {
            app.$api.laborOperations.find(this.value).then(record => {
              this.selectedOperation = record
            })
          } else {
            this.selectedOperation = {}
          }
        },
        immediate: true
      }
    },
    methods: {
      updateOperation(operation) {
        this.selectedOperation = operation
        this.$emit('select', operation)
        this.$emit('input', operation.id)
      },
      operationTitle(operation) {
        if (operation && operation.id) {
          if (operation.plannedDuration) {
            return `${ operation.fullName || operation.name} (${ app.$options.filters.formatDuration(operation.totalDuration) }/${ app.$options.filters.formatDuration(operation.plannedDuration) }hrs)`
          } else {
            return operation.fullName || operation.name
          }
        } else {
          return
        }
      }
    },
  }
</script>

<style lang="scss">
.operationSelect {
  .autocomplete {
    .dropdown-item {
      white-space: normal;
    }

    .dropdown-content {
      max-height: 80vh;
    }
  }
}
</style>
