import Vue from 'vue'
import { SnackbarProgrammatic } from 'buefy'

Vue.prototype.$notices = {
  send(message, params={}) {
    const defaultParam = {
      type: 'is-teal-500',
      position: 'is-bottom-right',
      queue: false,
      duration: 2000,
      // actionText: null,
      indefinite: false,
      message
    }
    const options = Object.assign(defaultParam, params)

    // Playing with adding a timestamp to the message
    // options.message = options.message + ` <small>(<em>${moment().format('LTS')}</em>)</small>`

    SnackbarProgrammatic.open(options)
  }
}

Vue.prototype.$errors = {
  send(message, params={}) {
    const defaultParam = {
      type: "is-danger",
      position: 'is-bottom-right',
      queue: false,
      duration: 5000,
      // actionText: null,
      indefinite: false,
      message
    }
    const options = Object.assign(defaultParam, params)

    // Playing with adding a timestamp to the message
    // options.message = options.message + ` <small>(<em>${moment().format('LTS')}</em>)</small>`

    SnackbarProgrammatic.open(options)
  }
}
