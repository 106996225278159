import Vue from 'vue'
import axios from 'axios'
import user from '_main/user'
import state from '_main/state'

Vue.prototype.$http = axios.create({ responseType: 'json' })
Vue.prototype.$http.interceptors.request.use(
  config => {
    config.headers.common['Authorization'] = utils.dig(user, 'authorization')
    return config;
  },
  error => Promise.reject(error)
);

Vue.prototype.$http.interceptors.response.use(
  response => response,
  error => {
    if (user.authorized && utils.dig(error, 'response.status') === 401) {
      user.logout()
    }
    if(state.loading) {
      console.log('Vue.prototype.$http.interceptors loading finished')
      state.loading = false
    }
    throw error;
  }
)

export default Vue.prototype.$http
