import Vue from 'vue'
import router from "_main/router"

Vue.prototype.$navigate = {
  newCard(stageId=null) {
    if (app.$user.canAddJob) {
      router.push({ name: 'jobs', params: {id: 'new'}, query: { stageId: stageId } })
    } else {
      app.$errors.send("Addtional permissions needed to add a card.")
    }
  },
  viewCard(card, section='') {
    router.push({ name: 'jobs', params: {id: card.id, scrollTo: {section: section}}})
  },
  viewWorkOrder(workOrder, card) {
    router.push({ name: 'jobs', params: {id: utils.dig(card, 'id'), scrollTo: {section: 'WorkOrder', id: workOrder.id}}, hash: `#woId${workOrder.id}`})
  },
  adminViewMemberTimeLogs(userId, weekEndsAt) {
    router.push({name: 'adminMemberTimeCalendar', params: {userId: userId}, query: {weekEndsAt: weekEndsAt} })
  }
}
