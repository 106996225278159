<template lang="pug">
  div.modal-card.is-full-height
    header.modal-card-head
      div.modal-card-title Time Entry

    div.modal-card-body
      div.columns.h-100p
        div.column.h-100p
          FullCalendar(ref="timeEntryCalendar" :options='calendarOptions')
        div.column.h-100p
          b-field(label="Operation")
            OperationSelect(
              v-model="timeEntryData.card_id"
              @select="updateOperation"
            )

          b-field(v-if="teamRequired" grouped)
            b-field(label="Team" expanded)
              TeamSelect(
                expanded
                v-model="timeEntryData.team_id"
              )

          b-field(label="Date")
            b-datepicker(
              v-model="timeEntryWorkDate"
              editable
            )
            div.m-1
              b-button.control(@click="workDatePrev" type="is-link" size="is-small" outlined) Prev
              b-button.control(@click="workDateToday" type="is-link" size="is-small" outlined :hovered="workDateIsToday") Today
              b-button.control(@click="workDateNext" type="is-link" size="is-small" outlined) Next

          b-field(label="Start Time")
            b-timepicker(
              v-model="timeEntryStartTime"
              hourFormat="24"
              editable
            )
            div.m-1
              b-button.control(@click="startTimeMinus" type="is-link" size="is-small" outlined) -5min
              b-button.control(@click="startTimeNow" type="is-link" size="is-small" outlined) Now
              b-button.control(@click="startTimePlus" type="is-link" size="is-small" outlined) +5min

          b-field(label="End Time")
            b-timepicker(
              v-model="timeEntryEndTime"
              hourFormat="24"
              editable
            )

            div.m-1
              b-button.control(@click="endTimeMinus"  type="is-link" size="is-small" outlined) -5min
              b-button.control(@click="endTimeNow" type="is-link" size="is-small" outlined) Now
              b-button.control(@click="endTimePlus" type="is-link" size="is-small" outlined) +5min

          div
            b-field(
              :label="`Duration: ${utils.formatDurationClock(duration)} H:M`"
              :type="maxDurationExeeded ? 'is-danger' : null"
              :message="maxDurationExeeded ? 'Max duration is 24 hours' : null"
              expanded
            )

          b-field(label="Notes")
            VueEditor.content(v-model="timeEntryData.content")

          b-field(label="Comments")
            div.timeline.compact
              div.timeline-item.pl-2.mb-2.pb-0.max-width-50rem(v-for="comment in timeEntryData.comments")
                div.content.box.p-2.r-2
                  p.heading.mb-1
                    span.is-size-7 {{ utils.formatDateTime(comment.commentedAt) }} - {{ utils.dig(comment, 'user.name') }}

                  div(v-html="utils.sanitize(comment.content)").pl-1

              div.timeline-item.compact.pl-2
                div.timeline-content.is-full-width.pr-2
                  VueEditor(
                    v-model="currentComment.content"
                    :toolbarDisplayed="false"
                    placeholder="Add comment"
                  )
                  div.has-content-right
                    AppButton.is-small.is-primary(@click="submitComment") Submit Comment

    footer.modal-card-foot
      app-button(type="delete" @click="openDestroyModal").is-danger Delete!

      div.spacer
      app-button(:disabled="!canSave" icon="save" @click="save").is-save Save
      //- app-button(:disabled="!canSave || !timeEntryEndTime" icon="save" @click="saveAndNew").is-saveNew Save &amp; New
      app-button(:disabled="!canSave" icon="save" @click="saveAndClose").is-saveClose Save &amp; Close
      app-button(icon="cancel" @click="close").is-dark
</template>

<script>
  import { ModalProgrammatic } from 'buefy'
  import FullCalendar from '@fullcalendar/vue'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import ConfirmModal from 'components/ConfirmModal'
  import OperationSelect from 'components/cards/OperationSelect'
  import listPlugin from '@fullcalendar/list'

  import 'api/timeEntries'
  function defaultComment() {
    return {
      user: {},
      commentedAt: new Date(),
      content: '',
    }
  }
  export default {
    name: 'CalendarTimeEntryModal',
    components: {OperationSelect, FullCalendar},
    props: {
      timeEntry: {
        type: Object
      },
      userId: {
        type: [Number, String]
      }
    },
    data() {
      return {
        timeEntryData: {},
        currentComment: defaultComment(),
        loadedTimeEntries: [],
        timeRangeStart: null,
        timeRangeEnd: null,
        calendarOptions: {
          allDaySlot: false,
          nowIndicator: true,
          height: '100%',
          editable: true,
          initialDate: this.timeEntry.workDate,
          scrollTime: moment(this.timeEntry.startTime).subtract(30, 'minutes').format('HH:mm'),
          slotDuration: '00:15:00',
          slotLabelInterval: '00:15:00',
          defaultTimedEventDuration: '00:15',
          eventMinHeight: 20,
          slotLabelFormat: {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
          },
          slotLabelContent: info => info.text.replace(/24:00/g, '00:00'),
          eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          },
          plugins: [interactionPlugin,timeGridPlugin,listPlugin],
          initialView: 'timeGridDay',
          headerToolbar: {
            start: 'prev,next today',
            center: 'title',
            end: 'timeGridDay,listDay'
          },
          dateClick: this.dateClicked,
          eventDrop: this.entryDropped,
          eventResize: this.entryResized,
          datesSet: this.handleViewRender,
          eventClick: this.eventClicked,
          events: []
        }
      }
    },
    computed: {
      canSave() {
        return !this.maxDurationExeeded && this.timeEntryData.team_id && this.timeEntryData.card_id && this.timeEntryStartTime
      },
      duration() {
        let startTime = this.timeEntryStartTime
        let endTime   = this.timeEntryEndTime || this.currentEventEndTime(this.timeEntryData)
        if (startTime && endTime) {
          return (endTime - startTime) / 1000
        }
      },
      maxDurationExeeded() {
        return this.duration > 24 * 60 * 60
      },
      teamRequired() {
        return this.timeEntryData.card_id && !this.timeEntryData.team_id
      },
      calendar() {
        return this.$refs.timeEntryCalendar.getApi()
      },
      workDateIsToday() {
        return moment(this.timeEntryWorkDate).isSame(moment(), 'day')
      },
      timeEntryWorkDate: {
        get() {
          return this.timeEntryData.workDate?.toDate()
        },
        set(value) {
          this.timeEntryData.workDate = value
        }
      },
      timeEntryStartTime: {
        get() {
          return this.timeEntryData.startTime?.toDate()
        },
        set(value) {
          if (value && this.timeEntryWorkDate) {
            this.timeEntryData.startTime = this.overrideTimeWorkDate(value, this.timeEntryWorkDate)
          } else {
            this.timeEntryData.startTime = null
          }
        }
      },
      timeEntryEndTime: {
        get() {
          return this.timeEntryData.endTime?.toDate()
        },
        set(value) {
          if (value && this.timeEntryWorkDate) {
            this.timeEntryData.endTime = this.overrideTimeWorkDate(value, this.timeEntryWorkDate)
          } else {
            this.timeEntryData.endTime = null
          }
        }
      },
    },
    watch: {
      timeEntry: {
        handler: 'setTimeEntryData',
        immediate: true
      },
      timeEntryData: {
        handler: 'updateEvents',
        deep: true,
        immediate: true
      },
      loadedTimeEntries: {
        handler: 'updateEvents',
        deep: true,
        immediate: true
      },
    },
    methods: {
      workDatePrev() {
        this.updateWorkDate(moment(this.timeEntryWorkDate).subtract(1, 'day'))
      },
      workDateToday() {
        this.updateWorkDate(moment())
      },
      workDateNext() {
        this.updateWorkDate(moment(this.timeEntryWorkDate).add(1, 'day'))
      },
      updateWorkDate(date) {
        this.timeEntryWorkDate  = date
        this.timeEntryStartTime = this.overrideTimeWorkDate(this.timeEntryStartTime, date)
        this.timeEntryEndTime   = this.overrideTimeWorkDate(this.timeEntryEndTime, date)
        this.calendar.gotoDate(this.timeEntryWorkDate)
      },
      overrideTimeWorkDate(time, workDate) {
        if (time && workDate) {
          let timeM = moment(time)
          let workDateM = moment(workDate)
          timeM.set({
            year: workDateM.year(),
            month: workDateM.month(),
            date: workDateM.date(),
          })
          return timeM
        } else {
          return time
        }
      },
      startTimeMinus() {
        this.timeEntryStartTime = utils.roundTime(5, moment(this.timeEntryStartTime  || app.$state.now)).subtract(5, 'minutes')
      },
      startTimePlus() {
        this.timeEntryStartTime = utils.roundTime(5, moment(this.timeEntryStartTime  || app.$state.now)).add(5, 'minutes')
      },
      startTimeNow() {
        this.timeEntryStartTime = moment()
      },
      endTimeMinus() {
        const endTime = this.timeEntryEndTime || this.currentEventEndTime(this.timeEntryData)
        this.timeEntryEndTime = utils.roundTime(5, moment(endTime)).subtract(5, 'minutes')
      },
      endTimePlus() {
        const endTime = this.timeEntryEndTime || this.currentEventEndTime(this.timeEntryData)
        this.timeEntryEndTime = utils.roundTime(5, moment(endTime)).add(5, 'minutes')
      },
      endTimeNow() {
        this.timeEntryEndTime = moment()
      },
      dateClicked(info) {
        // app.log('dateClicked', info)
        app.$http.get(Routes.new_time_entry_path({timeEntry: {startTime: info.date}})).then(response => {
          this.setTimeEntryData(response.data.timeEntry)
        })
      },
      save() {
        return app.$api.timeEntries.save(this.timeEntryData).then(record => {
          this.$notices.send('Time Entry Updated')
          this.setTimeEntryData(record)
          this.$emit('saved', this.timeEntryData)
          this.loadTimeEntries()
        }).catch(error => {
          let data = error.response.data
          if (data.errors.card) {
            this.$errors.send('Please select Operation')
          } else {
            this.$errors.send(data.error_messages.join(', '))
          }
        })
      },
      saveAndNew() {
        this.save().then(() => {
          this.setTimeEntryData({})
        })
      },
      saveAndClose() {
        this.save().then(() => {
          this.close()
        })
      },
      close() {
        this.$emit('close')
      },
      openDestroyModal() {
        ConfirmModal.open('destroy', 'Delete Time Entry', `Are you sure you want to delete this Time Entry?`,{
          events: {
            destroy: this.destroy,
          }
        })
      },
      destroy() {
        app.$api.timeEntries.destroy(this.timeEntryData.id).then(() => {
          this.$notices.send("Time Entry Deleted.");
          this.loadTimeEntries().then(() => {
            const timeEntry = utils.last(this.loadedTimeEntries)

            if (timeEntry) {
              this.setTimeEntryData(timeEntry)
            } else {
              this.timeEntryData.card_id = null
              this.timeEntryData.title = 'Select Operation'
            }
          })
          // this.close();
        });
      },
      updateOperation(operation) {
        // app.log('updateOperation', operation)
        this.timeEntryData.title = operation.fullName
        this.timeEntryData.team_id = operation.team_id
      },
      setTimeEntryData(timeEntry) {
        // app.log('setTimeEntryData', timeEntry)
        this.timeEntryData = {
          ...timeEntry,
          time_card_id: timeEntry.time_card_id || app.$timeClock.currentTimeCard.id,
          workDate:  timeEntry.workDate  ? moment(timeEntry.workDate)  : this.$state.now,
          startTime: timeEntry.startTime ? moment(timeEntry.startTime) : this.$state.now,
          endTime:   timeEntry.endTime   ? moment(timeEntry.endTime)   : null,
        }
      },
      updateEvents() {
        this.calendarOptions.events.splice(0)
        this.loadedTimeEntries.forEach(timeEntry => {
          this.calendarOptions.events.push({
            title: timeEntry.title,
            id: timeEntry.id,
            start: timeEntry.startTime,
            end: timeEntry.endTime || this.currentEventEndTime(timeEntry),
          })
        })
        this.calendarOptions.events.push({
          id: this.timeEntryData.id,
          title: this.timeEntryData.title,
          start: this.timeEntryStartTime,
          end: this.timeEntryEndTime || this.currentEventEndTime(this.timeEntryData),
          backgroundColor: 'green',
        })
      },
      currentEventEndTime(timeEntry) {
        if (timeEntry?.startTime && moment(timeEntry.startTime).isSame(moment(), 'day')) {
          return moment().diff(moment(timeEntry.startTime), 'minutes') > 15 ? app.$state.now : moment(timeEntry.startTime).add(15, 'minutes')
        } else {
          return moment(timeEntry.startTime).add(15, 'minutes')
        }
      },
      entryDropped(info) {
        let timeEntry = this.loadedTimeEntries.find(timeEntry => timeEntry.id == info.event.id)
        if (timeEntry) { this.setTimeEntryData(timeEntry) }

        this.timeEntryStartTime = info.event.start
        this.timeEntryEndTime = info.event.end
        if (this.canSave && this.timeEntryData.id) { this.save() }
      },
      entryResized(info) {
        let timeEntry = this.loadedTimeEntries.find(timeEntry => timeEntry.id == info.event.id)
        if (timeEntry) { this.setTimeEntryData(timeEntry) }

        this.timeEntryEndTime = info.event.end
        if (this.canSave && this.timeEntryData.id) { this.save() }
      },
      loadTimeEntries() {
        // app.log('loadTimeEntries')
        return app.$http.get(Routes.time_entries_path({users: this.userId, timeRangeStart: this.timeRangeStart, timeRangeEnd: this.timeRangeEnd})).then(response => {
          this.loadedTimeEntries = response.data.timeEntries
        })
      },
      handleViewRender(info) {
        const view = info.view
        if (!moment(this.timeRangeStart).isSame(view.activeStart) || !moment(this.timeRangeEnd).isSame(view.activeEnd)) {
          // app.log('handleViewRender(info) - loadTimeEntries')
          this.timeRangeStart = view.activeStart
          this.timeRangeEnd = view.activeEnd
          this.loadTimeEntries()
        }
      },
      eventClicked(info) {
        // app.log('eventClicked', info)
        let timeEntry = this.loadedTimeEntries.find(timeEntry => timeEntry.id == info.event.id)
        this.setTimeEntryData(timeEntry)
      },
      submitComment() {
        this.currentComment.user = app.$user.user;
        this.currentComment.commentedAt = new Date();
        this.$http.post(Routes.submit_comment_path({commentable_type: 'TimeEntry', commentable_id: this.timeEntryData.id}), {
          comment: utils.toJSON(this.currentComment)
        }).then(response => {
          this.timeEntryData.comments.push(response.data.comment);
        })
        this.currentComment = defaultComment()
      },
    },
    mounted() {
      this.$emit('mounted')
    },
    open(timeEntry={}, options={}) {
      let modalOptions = {
        component: this,
        fullScreen: true,
        canCancel: ['escape'],
        props: {
          timeEntry: timeEntry,
          userId: options.userId,
        }
      }
      return app.timeEntryModal = ModalProgrammatic.open(_.merge(modalOptions, options))
    },
  }
</script>
